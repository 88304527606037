export const pageurl = {
    LOGIN: '/',
    OVERVIEW: '/dashboard',
    OFFENCES_CAPTURED: '/dashboard/offences-captured',
    PROFILE: '/dashboard/profile'
};

export const danfo_baseurl = 'https://dev-myetraffika.azurewebsites.net/api';

export const validEmailRegex = /\S+@\S+\.\S+/;


// export const token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxNiIsImVtYWlsIjoiZGF2aW5jaUBnbWFpbC5jb20iLCJyb2xlIjoiMSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2hvbWVwaG9uZSI6IisyMzQ4MTIzNDU2NzgiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9tb2JpbGVwaG9uZSI6IisyMzQ4MTIzNDU2NzgiLCJuYmYiOjE2MjM1ODczMzUsImV4cCI6MTkzOTEyMDEzNSwiaWF0IjoxNjIzNTg3MzM1fQ.Hcn8XcrQulkRCghBMOkUQnnqdZNoi7qV01F61dyDBGj-Xu4UWOMJpVBwE0ImHuVKa6gGZCcbzlP45mkgTcg63Q";