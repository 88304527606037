import { 
	SET_ADMIN_AUTHENTICATED, SET_ADMIN_UNAUTHENTICATED, AXIOS_REQUEST, AXIOS_REQUEST_SUCCESS, AXIOS_REQUEST_FAILURE, 
	SET_TOTAL_COUNT, SET_ALL_OFFENCES, SET_OFFENCE_BY_SEARCH, SET_TOTAL_SEARCH_COUNT, SET_COMMENTS_BY_ID, 
} from "./types";
import { ActionEnums } from '../../enums/ActionEnums'
import { HttpMethod } from '../../enums/HttpEnums'
import { pageurl, danfo_baseurl } from "../../utils/constants";
import axios from "axios";
import { getHttpError, getHttpSuccess } from './httpActions';

const setAuthorizationHeader = (token: string) => {
	const DanfoAdminAppToken = `Bearer ${token}`;
	localStorage.setItem("DanfoAdminAppToken", DanfoAdminAppToken);
	axios.defaults.headers.common["Authorization"] = DanfoAdminAppToken;
};

const DanfoAdminAppToken = localStorage.getItem('DanfoAdminAppToken');

export const loginAdmin = (adminDetails: object) => (dispatch: Function) => {
	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.LOGIN });
	axios({url: `${danfo_baseurl}/Auth/AdminLogin`, method:'post', data: adminDetails, validateStatus : ()=> true })
		.then((res) => {
			const { token } = res.data;
			setAuthorizationHeader(token);
			dispatch({type: SET_ADMIN_AUTHENTICATED})
			dispatch({type: AXIOS_REQUEST_SUCCESS })
			res.status === 200 ? window.location.href = pageurl.OVERVIEW : void(0);
			getHttpSuccess( dispatch, ActionEnums.LOGIN, res, HttpMethod.POST )
		})
		.catch((err) => {
			dispatch({
				type: AXIOS_REQUEST_FAILURE,
				payload: err.message,
			});
			getHttpError( err, dispatch, ActionEnums.LOGIN, HttpMethod.POST )
		});
};

export const getAllOffences = (pageNumber?: number, pageSize?: number ) => (dispatch: Function) => {
	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.GET_ALL_OFFENCES })
	axios({url: pageNumber !== undefined && pageSize !== undefined ?`${danfo_baseurl}/v1/Offenses/GetAllOffense?pageNumber=${pageNumber}&pageSize=${pageSize}` : `${danfo_baseurl}/v1/Offenses/GetAllOffense`, method:'get', validateStatus : ()=> true, headers:{Authorization: DanfoAdminAppToken} })
	.then((res) => {
		dispatch({ type: AXIOS_REQUEST_SUCCESS })
		dispatch({
			 type: SET_ALL_OFFENCES,
			 payload: res.data.offenses
		})
		dispatch({
			type: SET_TOTAL_COUNT,
			payload: res.data.totalCount
	   })
		getHttpSuccess( dispatch, ActionEnums.GET_ALL_OFFENCES, res, HttpMethod.GET )
	})
	.catch((err) => {
		dispatch({
			type: AXIOS_REQUEST_FAILURE,
			payload: err.message,
		});
		getHttpError( err, dispatch, ActionEnums.GET_ALL_OFFENCES , HttpMethod.GET )
	});
};

export const getOffencesBySearch = (query: string, pageNumber?: number, pageSize?: number ) => (dispatch: Function) => {
	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.GET_OFFENCES_BY_SEARCH })
	axios({url: `${danfo_baseurl}/v1/Offenses/GetAllOffense?query=${query}&pageNumber=${pageNumber}&pageSize=${pageSize}`, method:'get', validateStatus : ()=> true, headers:{Authorization: DanfoAdminAppToken} })
	.then((res) => {
		dispatch({ type: AXIOS_REQUEST_SUCCESS })
		dispatch({
			 type: SET_OFFENCE_BY_SEARCH,
			 payload: res.data.offenses
		})
		dispatch({
			type: SET_TOTAL_SEARCH_COUNT,
			payload: res.data.totalCount
	   })
		getHttpSuccess( dispatch, ActionEnums.GET_OFFENCES_BY_SEARCH, res, HttpMethod.GET )
	})
	.catch((err) => {
		dispatch({
			type: AXIOS_REQUEST_FAILURE,
			payload: err.message,
		});
		getHttpError( err, dispatch, ActionEnums.GET_OFFENCES_BY_SEARCH , HttpMethod.GET )
	});
};

export const clearOffencesBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_OFFENCE_BY_SEARCH } )
}


export const updateOffenceStatus = (offenceInfo: object) => (dispatch: Function) => {
	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.UPDATE_OFFENCE_STATUS })
	axios({url: `${danfo_baseurl}/v1/Offenses/UpdateOffenseStatus`, method:'post', data: offenceInfo, validateStatus : ()=> true, headers:{Authorization: DanfoAdminAppToken} })
	.then((res) => {
		dispatch({ type: AXIOS_REQUEST_SUCCESS })
		getHttpSuccess( dispatch, ActionEnums.UPDATE_OFFENCE_STATUS, res, HttpMethod.POST )
	})
	.catch((err) => {
		dispatch({
			type: AXIOS_REQUEST_FAILURE,
			payload: err.message,
		});
		getHttpError( err, dispatch, ActionEnums.UPDATE_OFFENCE_STATUS , HttpMethod.POST )
	});
};

export const postComment = (commentInfo: object) => (dispatch: Function) => {
	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.POST_COMMENT })
	axios({url: `${danfo_baseurl}/v1/Comments/AddNew`, method:'post', data: commentInfo, validateStatus : ()=> true, headers:{Authorization: DanfoAdminAppToken} })
	.then((res) => {
		dispatch({ type: AXIOS_REQUEST_SUCCESS })
		getHttpSuccess( dispatch, ActionEnums.POST_COMMENT, res, HttpMethod.POST )
	})
	.catch((err) => {
		dispatch({
			type: AXIOS_REQUEST_FAILURE,
			payload: err.message,
		});
		getHttpError( err, dispatch, ActionEnums.POST_COMMENT , HttpMethod.POST )
	});
};

export const getCommentsById = (pageNumber?: number, pageSize?: number, id?: number ) => (dispatch: Function) => {
	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.GET_COMMENTS_BY_ID })
	axios({url: pageNumber !== undefined && pageSize !== undefined && id !== undefined ? `${danfo_baseurl}/v1/Offense/GetAllComments?pageNumber=${pageNumber}&pageSize=${pageSize}&offenseId=${id}` : `${danfo_baseurl}/v1/Offense/GetAllComments`, method:'get', validateStatus : ()=> true, headers:{Authorization: DanfoAdminAppToken} })
	.then((res) => {
		dispatch({ type: AXIOS_REQUEST_SUCCESS })
		dispatch({
			 type: SET_COMMENTS_BY_ID,
			 payload: res.data.comments
		})
		dispatch({
			type: SET_TOTAL_COUNT,
			payload: res.data.totalCount
	   })
		getHttpSuccess( dispatch, ActionEnums.GET_COMMENTS_BY_ID, res, HttpMethod.GET )
	})
	.catch((err) => {
		dispatch({
			type: AXIOS_REQUEST_FAILURE,
			payload: err.message,
		});
		getHttpError( err, dispatch, ActionEnums.GET_COMMENTS_BY_ID , HttpMethod.GET )
	});
};
	
export const logOutAdmin = () => (dispatch: Function) => {
	localStorage.removeItem('DanfoAdminAppToken');
	dispatch({ type: SET_ADMIN_UNAUTHENTICATED });
	delete axios.defaults.headers.common["Authorization"];
	localStorage.clear();
	window.location.href = pageurl.LOGIN;
};
