import { combineReducers } from "redux";
import adminDanfoReducer from "./adminDanfoReducer";
import httpReducer from './httpReducer'

const rootReducer = combineReducers({
    danfo: adminDanfoReducer,
    http: httpReducer,
});

export default rootReducer;
