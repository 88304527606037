import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IDanfo } from "../../interfaces/IDanfo";
import Modal from '../modal';

const SessionTimeout = ( danfo: IDanfo, logOutAdmin: () => void ) => {

    const { authenticated = false } = danfo;
    const [ events ] = useState(['click', 'load', 'scroll', 'mousemove']);
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);
  
    let timeStamp;
    let warningInactiveInterval = useRef<any>();
    let startTimerInterval = useRef<any>();
  
    // start inactive check
    let timeChecker = useCallback(() => {
      startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
        warningInactive(storedTimeStamp!);
      }, 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    // warning timer
    let warningInactive = (timeString: string) => {
      clearTimeout(startTimerInterval.current);
  
      warningInactiveInterval.current = setInterval(() => {
        const maxTime = 30;
        const popTime = 29;
  
        const diff = new Date().getTime() - new Date(timeString).getTime();
        const minPast = new Date(diff).getMinutes();
        const leftSecond = 60 - new Date(diff).getSeconds();
  
        if (minPast === popTime) {
          setSecond(leftSecond);
          setOpen(true);
        }
  
        if (minPast === maxTime) {
          clearInterval(warningInactiveInterval.current);
          setOpen(false);
          sessionStorage.removeItem('lastTimeStamp');
          logOutAdmin();
        }
      }, 1000);
    };
  
    // reset interval timer
    let resetTimer = useCallback(() => {
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
  
      if (authenticated) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timeStamp = new Date().toString();
        sessionStorage.setItem('lastTimeStamp', timeStamp);
      } else {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');
      }
      timeChecker();
      setOpen(false);
    }, [authenticated]);
  
    //handle close popup
    const handleClose = () => {
        setOpen(false);
        resetTimer();
    };
  
    useEffect(() => {
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
  
      timeChecker();
  
      return () => {
        clearTimeout(startTimerInterval.current);
          resetTimer();
      };
    }, [resetTimer, events, timeChecker]);
  
    if (!isOpen) {
      return null;
    }
    
    const OurModal = <Modal show={true} handleClose={handleClose}> 
                        <div style={{textAlign:'center'}}>
                            <h3>App would logout in {second} seconds due to inactivity...</h3>
                        </div>
                    </Modal>
    // change fragment to modal and handleclose func to close
    return [OurModal];
  };

export default SessionTimeout;
  