import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { pageurl } from '../constants';
// import { AdminActions } from '../../enums/Admins';



const Offences_Captured = React.lazy(() => import('../../components/offences-captured'));
const Overview = React.lazy(() => import('../../components/overview'));
const Profile = React.lazy(() => import('../../components/profile'));

export const route:Array<IUrl> = [
    {
        id : 1,
        PageRender : Offences_Captured,
        path : `${pageurl.OFFENCES_CAPTURED}/:id?`,
        // view : AdminActions.vehicle_view
    },
    {
        id : 2,
        PageRender : Overview,
        path : pageurl.OVERVIEW,
        // view : AdminActions.vehicle_view
    },
    {
        id : 3,
        PageRender : Profile,
        path : pageurl.PROFILE,
        // view : AdminActions.vehicle_view
    }
    
];

export interface IUrl {
    id : number;
    PageRender : React.ComponentType<RouteComponentProps<any, any, unknown>> | React.ComponentType<any> | undefined;
    path : string;
    view? : string;
};

