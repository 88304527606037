import React, { FC } from 'react';
import { connect } from "react-redux";
import { IAppState } from "./interfaces/IAppState";
import { IDanfo } from "./interfaces/IDanfo";
import { Redirect, Route } from 'react-router-dom';
import { route } from './utils/route';
import SessionTimeout from './utils/auto-logout';
import { logOutAdmin } from './store/actions'
import { pageurl } from './utils/constants';

interface IStateProps {
	danfo: IDanfo;
}

interface IDispatchProps {
    logOutAdmin:()=>void;
};

interface IProps extends IStateProps, IDispatchProps {}

const Routes:FC<IProps> = (props) => {
    const { danfo, logOutAdmin } = props;
    const Wow = SessionTimeout( danfo, logOutAdmin );

    return(
        <>
            {danfo.authenticated ?
             <>
                    {route.map( ( item ) => {
                        return( 
                        // checkRole(item.view!, danfo.danfoRoles) &&
                        <Route exact path={ item.path } key={ item.id } component={item.PageRender} /> ) 
                    })}
                    </>
            :
            <Redirect to={{ pathname: pageurl.LOGIN }} /> }
            {Wow}
        </>
    );
};

const mapStateToProps = (state: IAppState): IStateProps => ({
	danfo: state.danfo,
});

const mapDispatchToProps: IDispatchProps = {
    logOutAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);