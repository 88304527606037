
export enum ActionEnums {
    //user
    LOGIN = 'Login',

    //offences
    GET_ALL_OFFENCES = 'Get all offences',
    GET_OFFENCES_BY_ID = 'Get offence by id',
    GET_OFFENCES_BY_SEARCH = 'Get offence by search',
    UPDATE_OFFENCE_STATUS = 'Update Offence Status',

    //comments
    POST_COMMENT = 'Post Comment',
    GET_COMMENTS_BY_ID = 'Get comments by id',
}