import React from 'react';
import { connect } from "react-redux";
import { IAppState } from "../../interfaces/IAppState";
import { IDanfo } from "../../interfaces/IDanfo";
import { Route, Redirect } from 'react-router-dom';
import { pageurl } from '../constants';

interface IStateProps {
	danfo: IDanfo;
}

interface IProps extends IStateProps {
    exact?: boolean;
    Component: React.ComponentType<any>;
    path?: string | string[] | undefined
 }

const ProtectedRoute: React.FC<IProps> = (props, ...rest) => {
    const { danfo, Component, exact } = props;
    return(
        <Route
          {...rest}
          exact={exact}
          render={props =>
            !danfo.authenticated ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: pageurl.OVERVIEW
                }}
              />
            )
          }
        />
    );
};

const mapStateToProps = (state: IAppState): IStateProps => ({
	danfo: state.danfo,
});

export default connect(mapStateToProps)(ProtectedRoute);