import {
	AXIOS_REQUEST, AXIOS_REQUEST_FAILURE, AXIOS_REQUEST_SUCCESS, SET_ADMIN_AUTHENTICATED, SET_ADMIN_UNAUTHENTICATED,
	SET_OFFENCE_BY_ID, SET_ALL_OFFENCES, SET_OFFENCE_BY_SEARCH, SET_TOTAL_COUNT, SET_TOTAL_SEARCH_COUNT, SET_COMMENTS_BY_ID
} from "../actions/types";

const initialState = {
	authenticated: false,
	loading: false,
	action: '',
	errors: '',
	data: [],
	offences: [],
	offenceById: {},
	offencesBySearch: [],
	commentsById: [],
	totalCount:0,
	totalSearchCount:0,
};

const adminDanfoReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
		case SET_ADMIN_AUTHENTICATED:
			return {
				...state,
				authenticated: true,
			};
		case SET_ADMIN_UNAUTHENTICATED:
			return {
				...state,
				authenticated: false
			};
		case SET_OFFENCE_BY_ID:
			return {
				...state,
				offenceById: action.payload
			};
		case SET_OFFENCE_BY_SEARCH:
			return {
				...state,
				offencesBySearch: action.payload
			};
		case SET_ALL_OFFENCES:
			return {
				...state,
				offences: action.payload
			};
		case SET_COMMENTS_BY_ID:
			return {
				...state,
				commentsById: action.payload
			};
		case SET_TOTAL_COUNT:
			return {
				...state,
				totalCount : action.payload
			};
		case SET_TOTAL_SEARCH_COUNT:
			return {
				...state,
				totalSearchCount : action.payload
			};
		default:
			return state;
	};
};

export default adminDanfoReducer;