import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {Switch, BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {pageurl} from './utils/constants';
// import Navbar from './components/navigations/navbar';
import RouteList from './routes';
import loader from './extras/images/loader/loader.svg'
import * as serviceWorker from './serviceWorker';
import AuthRoute from './utils/protectedRoute';
import './index.scss';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat',
  }
});

const Login = React.lazy(() => import('./components/admin'));

const Routes = () => (
  <Router>
    {/* <Navbar/> */}
    <Suspense fallback={ <div className="loader-container"><img src={ loader } alt="loader icon" /></div> } >  

      <Switch>

          <AuthRoute exact path={pageurl.LOGIN} Component={Login} />
          <RouteList />

      </Switch>

    </Suspense>  
  </Router>
);

ReactDOM.render(
  <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
