//Reducer Types
export const SET_ADMIN_AUTHENTICATED = 'SET_ADMIN_AUTHENTICATED';
export const SET_ADMIN_UNAUTHENTICATED = 'SET_ADMIN_UNAUTHENTICATED';
export const AXIOS_REQUEST = 'AXIOS_REQUEST';
export const AXIOS_REQUEST_SUCCESS = 'AXIOS_REQUEST_SUCCESS';
export const AXIOS_REQUEST_FAILURE = 'AXIOS_REQUEST_FAILURE';
export const SET_STATUS = 'SET_STATUS';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_HTTP_STATUS = 'SET_HTTP_STATUS';
export const SET_HTTP_ACTION = 'SET_HTTP_ACTION';
export const SET_HTTP_METHOD = 'SET_HTTP_METHOD';
export const CLEAR_HTTP = 'CLEAR_HTTP';
export const SET_OFFENCE_BY_ID = 'SET_OFFENCE_BY_ID';
export const SET_OFFENCE_BY_SEARCH = 'SET_OFFENCE_BY_SEARCH';
export const SET_ALL_OFFENCES = 'SET_ALL_OFFENCES';
export const SET_COMMENTS_BY_ID = 'SET_COMMENTS_BY_ID';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_TOTAL_SEARCH_COUNT = 'SET_TOTAL_SEARCH_COUNT';

